<template>
  <div class="w-100">
    <div class="header-print">
      <div class="d-flex mx-1 my-2" style="gap: 10px">
        <div class="d-flex">
          <a-checkbox
            :default-checked="printDetailProduk"
            @change="setDetailProduk"
          >
            {{ $t('fulfillment.print_details') }}
          </a-checkbox>
        </div>
        <div class="d-flex">
          <a-checkbox :default-checked="true" @change="setPrintFulfillmentId">
            Cetak dengan ID Pengiriman
          </a-checkbox>
        </div>
      </div>
      <div class="print-button" onclick="window.print()">
        <svg
          style="margin-right: 1rem"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 36 36"
        >
          <path
            fill="currentColor"
            d="M29 9h-2V5H9v4H7a4 4 0 0 0-4 4v11h3.92v-1.91H5V13a2 2 0 0 1 2-2h22a2 2 0 0 1 2 2v9h-1.92v2H33V13a4 4 0 0 0-4-4Zm-4 0H11V7h14Z"
            class="clr-i-outline clr-i-outline-path-1"
          />
          <path
            fill="currentColor"
            d="M28 18H8a1 1 0 0 0 0 2h1v12h18V20h1a1 1 0 0 0 0-2Zm-3 12H11V20h14Z"
            class="clr-i-outline clr-i-outline-path-2"
          />
          <path
            fill="currentColor"
            d="M27 13.04h2v2h-2z"
            class="clr-i-outline clr-i-outline-path-3"
          />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
        Print Slip
      </div>
    </div>

    <div class="page">
      <table width="100%">
        <tr class="pagebreak">
          <td class="page-section shipping-label" style="height: 563pt">
            <div class="unboxing-warning" style="margin-bottom: 10px">
              <img src="@/assets/images/unboxing-warning.webp" width="100%" />
            </div>
            <div
              class="d-flex justify-items-center text-center justify-content-center"
            >
              <div class="d-flex flex-column">
                <VueBarcode
                  v-model="detail[0].fulfillment_number"
                  :options="barcodeSmall"
                />
                <div class="bold" style="margin-top: 0px">
                  576699473151099919
                </div>
                <div class="bold" style="margin-top: 4px; font-size: 80%">
                  PCA/5590689
                </div>
              </div>
            </div>
            <canvas ref="pdfCanvas" />
          </td>
        </tr>

        <tr class="pagebreak">
          <td class="page-section shipping-label" style="height: 563pt">
            <div class="unboxing-warning" style="margin-bottom: 10px">
              <img src="@/assets/images/unboxing-warning.webp" width="100%" />
            </div>
            <div
              class="d-flex justify-items-center text-center justify-content-center"
            >
              <div class="d-flex flex-column">
                <VueBarcode
                  v-model="detail[0].fulfillment_number"
                  :options="barcodeSmall"
                />
                <div class="bold" style="margin-top: 0px">
                  576699473151099919
                </div>
                <div class="bold" style="margin-top: 4px; font-size: 80%">
                  PCA/5590689
                </div>
              </div>
            </div>

            <div :span="14" class="d-flex justify-content-end mt-2">
              <div class="label-shipment">
                <div class="label-shipment-content">
                  <a-row class="d-flex">
                    <a-col
                      :span="24"
                      style="margin: 10px; text-align: left; font-size: 12px"
                    >
                      <div class="d-flex" style="font-weight: bold">
                        Pengirim
                      </div>
                      <div class="d-flex">
                        {{ sender?.name || shipment?.name || '' }}
                      </div>
                      <div class="d-flex">
                        {{ sender?.address || shipment?.address || '' }}
                      </div>
                      <div class="d-flex">
                        {{ sender?.phone || shipment?.phone || '' }}
                      </div>
                    </a-col>
                  </a-row>
                  <a-row class="d-flex">
                    <a-col
                      :span="24"
                      style="
                        margin: 10px 10px 0;
                        text-align: left;
                        font-size: 12px;
                      "
                    >
                      <div class="d-flex" style="font-weight: bold">
                        Penerima
                      </div>
                      <div class="d-flex">
                        {{ destination?.name || '-' }}
                      </div>
                      <div class="d-flex">
                        <template v-if="destination.address">
                          {{ destination.address?.line1 }}
                          {{ destination.address?.line2 }},
                          {{ destination.address?.sub_district }},
                          {{ destination.address?.district }},
                          {{ destination.address?.province }},
                          {{ destination.address?.postal_code }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                      <div class="d-flex">
                        {{ destination?.phone || '-' }}
                      </div>
                    </a-col>
                  </a-row>
                  <div
                    v-if="printFulfillmentId"
                    style="
                      margin-top: 10px;
                      font-weight: 600;
                      font-size: 14px;
                      text-align: center;
                      border-top: 1px dotted #cccccc;
                    "
                  >
                    <div v-if="isExist" style="margin-top: 10px">
                      <VueBarcode
                        v-model="detail[0].fulfillment_number"
                        :options="barcode"
                      />
                    </div>
                    <div v-if="isExist" style="margin-bottom: 15px">
                      ID Pengiriman: {{ detail[0].fulfillment_number }}
                    </div>
                  </div>
                  <div
                    style="
                      margin: 10px 0px;
                      padding-top: 10px;
                      font-size: 12px;
                      text-align: left;
                      border-top: 1px dotted #cccccc;
                    "
                  >
                    <a-row class="d-flex">
                      <a-col
                        :span="6"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          No Order
                        </div>
                      </a-col>
                      <a-col
                        :span="18"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          : {{ order.order_number }}
                        </div>
                      </a-col>
                    </a-row>
                    <a-row class="d-flex">
                      <a-col
                        :span="6"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          Tanggal Order
                        </div>
                      </a-col>
                      <a-col
                        :span="18"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          : {{ parseISOString(order.order_date) }}
                        </div>
                      </a-col>
                    </a-row>
                    <a-row class="d-flex">
                      <a-col
                        :span="6"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          Ongkos Kirim
                        </div>
                      </a-col>
                      <a-col
                        :span="18"
                        style="
                          margin: 0 10px;
                          font-weight: bold;
                          text-align: left;
                        "
                      >
                        <div class="d-flex">
                          : Rp {{ shipping.shipping_cost?.value }}
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div
                    style="
                      margin: 10px 0px;
                      padding-top: 10px;
                      font-size: 12px;
                      text-align: left;
                      border-top: 1px dotted #cccccc;
                    "
                  >
                    <a-row class="d-flex">
                      <a-col
                        :span="24"
                        style="margin: 0 10px; text-align: left"
                      >
                        <div class="d-flex">
                          Catatan :
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div
                    v-if="printDetailProduk"
                    style="
                      margin: 10px 0px;
                      font-size: 12px;
                      text-align: left;
                      border-top: 1px solid #cccccc;
                    "
                  >
                    <a-row class="d-flex" style="width: 100%">
                      <a-col :span="20" style="margin: 10px 10px">
                        <div>Produk</div>
                      </a-col>
                      <a-col :span="2" style="margin: 10px 0">
                        <div>Jumlah</div>
                      </a-col>
                    </a-row>
                    <a-row
                      v-for="(item, index) in product"
                      :key="index"
                      class="d-flex"
                      style="width: auto"
                    >
                      <a-col :span="20" style="margin: 1px 10px">
                        <div>{{ item.catalog_title }}</div>
                      </a-col>
                      <a-col :span="2" style="margin: 1px 0">
                        <div style="text-align: center">
                          <b>{{ item.quantity }}</b> {{ item.uom }}
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseById } from '@/api/warehouse'
// import { getStock } from '@/api/inventory'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import VueBarcode from '@chenfengyuan/vue-barcode'
import getQueryParam from '@/utils/getQueryParam'

export default {
  name: 'FulfillmentLabel',
  components: {
    ArrowIcon,
    VueBarcode,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      tokopedia: require('@/assets/integration/channel/tokopedia.png'),
      sicepat: require('@/assets/courier/sicepat.png'),
      anteraja: require('@/assets/courier/anteraja.png'),
      jne: require('@/assets/courier/jne.png'),
      sap: require('@/assets/courier/sap.png'),
      paxel: require('@/assets/courier/paxel.png'),
      detail: [],
      product: [],
      destination: [],
      shipping: {},
      warehouseId: '',
      size: '',
      printDetailProduk: this.$route.params.label_product,
      printFulfillmentId: true,
      shipment: {},
      order: {},
      sender: {},
      documentUrl:
        'https://powercommerce.s3.ap-southeast-1.amazonaws.com/public/order/label/9b8aaf84-ac37-4e32-b36c-6f8c4037b6aa.pdf',
    }
  },
  computed: {
    barcodeSmall() {
      return {
        height: 40,
        width: 2,
        displayValue: false,
      }
    },
    barcode() {
      return {
        height: 100,
        width: 1,
        displayValue: false,
      }
    },
    topBarcode() {
      return this.shipping.awb !== null
        ? this.shipping.awb
        : this.shipping.booking_no
    },
    isExist() {
      return this.detail[0] && this.detail[0].fulfillment_number
    },
  },
  watch: {
    'window.pdfjsLib': {
      deep: true,
      immediate: true,
      handler: function (value) {
        console.log('value=>', value)
      },
    },
  },
  created() {
    let pdfJS = document.createElement('script')
    pdfJS.setAttribute(
      'src',
      'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.min.js',
    )
    pdfJS.onload = () => {
      this.checkPDFJSLib()
    }
    document.head.appendChild(pdfJS)
  },
  async mounted() {
    this.fetchDetailFulfillmentBasedOrderId()
  },
  methods: {
    async checkPDFJSLib() {
      this.renderPDF('pdfCanvas')
      this.renderPDF('pdfCanvas2')
    },
    async renderPDF(ref) {
      const url = this.documentUrl // Replace this with your PDF file path
      const pdf = await window.pdfjsLib.getDocument(url).promise
      const page = await pdf.getPage(1)
      const scale = window.devicePixelRatio || 1
      const viewport = page.getViewport({ scale: scale })
      const canvas = this.$refs[ref]
      const context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }
      await page.render(renderContext)
    },
    // async renderPDF2() {
    //   const canvases = document.querySelectorAll('canvas')
    //   let loaded = 0

    //   function showPrintWindow(add = true) {
    //     if (add) loaded++

    //     if (canvases.length === 0 || loaded === canvases.length) {
    //       window.print()
    //     }
    //   }

    //   showPrintWindow(false)

    //   canvases.forEach(canvas => {
    //     const url = canvas.dataset.url
    //     const scale = canvas.dataset.scale || 3

    //     if (!url) return

    //     var loadingTask = window.pdfjsLib.getDocument(url)
    //     loadingTask.promise.then(
    //       function (pdf) {
    //         // Fetch the first page
    //         var pageNumber = 1
    //         pdf.getPage(pageNumber).then(function (page) {
    //           var viewport = page.getViewport({ scale: scale })

    //           // Prepare canvas using PDF page dimensions
    //           var context = canvas.getContext('2d')
    //           canvas.height = viewport.height
    //           canvas.width = viewport.width

    //           // Render PDF page into canvas context
    //           var renderContext = {
    //             canvasContext: context,
    //             viewport: viewport,
    //           }
    //           var renderTask = page.render(renderContext)
    //           renderTask.promise.then(function () {
    //             console.log('Page rendered')
    //             showPrintWindow()
    //           })
    //         })
    //       },
    //       function (reason) {
    //         // PDF loading error
    //         console.error(reason)
    //         showPrintWindow()
    //       },
    //     )
    //   })
    // },
    parseISOString(dateTime) {
      if (dateTime) {
        const split = dateTime.split(/\D+/)
        const newDate = new Date(
          split[0],
          --split[1],
          split[2],
          split[3],
          split[4],
          split[5],
          split[6],
        )
        return this.$moment(newDate).format('Do MMMM YYYY')
      } else {
        return ''
      }
    },
    getCourierLogo(param) {
      switch (param.toLowerCase()) {
        case 'sicepat':
          return this.sicepat
        case 'anteraja':
          return this.anteraja
        case 'jne':
          return this.jne
        case 'sap':
          return this.sap
        case 'paxel':
          return this.paxel
      }
    },
    setDetailProduk(e) {
      this.printDetailProduk = e.target.checked
    },
    setPrintFulfillmentId(e) {
      this.printFulfillmentId = e.target.checked
    },
    setPageSize(value) {
      const style = document.createElement('style')
      style.innerHTML = `@page {size: ${value}}`
      document.head.appendChild(style)
    },
    async fetchDetailFulfillmentBasedOrderId() {
      await getFulfillmentDetail({
        url: 'fulfillment',
        fulfillment_id: this.$route.query.fulfillment_id,
      })
      .then(({ data: { data: response } }) => {
        this.detail = response.fulfillments
        this.order = response.order
        this.getDataDetail()
        this.fetchWarehouseId(response.warehouse_id)
      })
      .catch(err => console.error(err))
    },
    async fetchWarehouseId(warehouseId) {
      await getWarehouseById(warehouseId)
      .then(({ data: { data: response } }) => this.shipment = response)
      .catch((err) => console.error(err))
    },
    getDataDetail() {
      const label_id = this.$route.query.fulfillment_id
      const data = this.detail.find(item => item.id === label_id)
      this.product = data.line_items
      this.destination = data.destination
      this.shipping = data.shipping
      this.sender = data.sender
    },
    back() {
      this.$router.push({
        name:
          this.$route.params.id === undefined
            ? 'fulfillment.index'
            : 'channel-fulfillment',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style lang="scss">
@page {
  // size: A5;
  margin: 0;
}

html {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  line-height: 1.2;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  line-height: 1;
  width: 100%;
  display: block;
  margin: 0 auto;
  color: #000;
  font-size: 9pt;
  background: white;
}
img {
  -ms-interpolation-mode: bicubic;
}

table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

table.table-bordered tr td {
  border: 1px solid #000;
}

table th {
  text-align: left;
}

table td {
  vertical-align: top;
}

.page {
  width: 100%;
  position: relative;
  /* display: inline-block; */
  vertical-align: top;
  padding: 0;
  // overflow: hidden;
  margin: auto;
  margin-top: 160px;
  height: 200mm;
  width: 140mm;
}

.label-shipment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 2px solid black;
}

.page-section {
  padding: 8pt;
  width: 50%;
  max-width: 50%;
}

.page-section:first-child {
  /* border-right: 1.5px dashed #ddd; */
}

/* Page Breaker */
.page-breaker {
  position: relative;
  text-align: center;
  page-break-before: always;
  margin-bottom: 20px;
}

.barcode {
  width: 100%;
}

.shipping-label {
  width: 43%;
  max-width: 43%;
}
.shipping-label table {
  border: 1px solid #444;
}
.shipping-label table td {
  padding: 4px 8px;
}

.package-slip {
  width: 57%;
  max-width: 57%;
}
.package-slip table {
  font-size: 8pt;
}
.package-slip table td {
  border-bottom: 1px solid #e1e1e1;
  padding: 5px 0 5px;
}

.package-slip table tr:last-child td {
  border-bottom: 0;
}

tr.dashed-bottom > td {
  border-bottom: 1.5px dashed #d1d1d1;
}
tr.dashed-top > td {
  border-top: 1.5px dashed #d1d1d1;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.light-italic {
  font-style: italic;
  font-weight: 300;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.v-top {
  vertical-align: top;
}
.v-middle {
  vertical-align: middle;
}
.v-bottom {
  vertical-align: bottom;
}

.w-25 {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}
.w-50 {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.fs-6 {
  font-size: 6pt;
}
.fs-7 {
  font-size: 7pt;
}
.fs-8 {
  font-size: 8pt;
}
.fs-9 {
  font-size: 9pt;
}
.fs-10 {
  font-size: 10pt;
}
.fs-11 {
  font-size: 11pt;
}
.fs-12 {
  font-size: 12pt;
}

.fs-14 {
  font-size: 14pt;
}
.fs-16 {
  font-size: 16pt;
}

.text-muted {
  color: #404040;
}

canvas {
  width: 100%;
  height: fit-content;
}

.print-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #333;
  padding: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
  max-width: 290mm;
  margin: 2rem auto;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.print-button img {
  margin-right: 1rem;
}

.header-print {
  position: fixed;
  width: 100%;
  top: 0;
  background: white;
  z-index: 10;
  padding: 5px 0px;
}

@media only print {
  .print-button {
    display: none;
  }

  .header-print {
    display: none;
  }

  .pagebreak {
    page-break-before: always;
  }

  .page {
    width: 85%;
    margin: auto;
  }
}
</style>
